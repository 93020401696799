module.exports = {
  ENV: 'prod',
  APP_NAME: 'semm',
  END_POINT: 'https://ssm.services.apihf.com',
  API_KEY: 'o0q33qpc5c9ctxq8Oc3mGaERZMs6J83G5rGhENH7',
  BRAND_KEY: '1d85b844-2bdf-4194-9c04-79035c5d5d17',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'SEMM_AGENTS',
}